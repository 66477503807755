import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store"
import { nextTick } from 'vue/types/umd'

// linkExactActiveClass: "vue-school-active-class",

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  
  {
    path: '/',
    name: 'Home',
    meta: {requiresAuth: false},
    component: Home,
    props: true

  },
  {
    path: "/destination/:slug",
    name: "DestinationDetails",
    meta: {requiresAuth: true},
    props: true,
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "DestinationDetails"*/ '../views/DestinationDetails.vue'),

    children:[
      {
        path: ":experienceSlug",
        name: "experienceDetails",
        
        props: true,
        component:() => import(/* webpackChunkName: "ExperienceDetails"*/ '../views/ExperienceDetails.vue'),

        // beforeEnter:(to, from, next)=>{
        //   console.log(to)
        // }


      }

    ],
    beforeEnter:(to, from, next)=>{
      
      const exists = store.destinations.find(
        destination => destination.slug === to.params.slug
      )
      if(exists){
        next()
      }
      else{
        next({name:'notFound'})
      } 
    }
  },
  {
    path: "/user",
    name:"user",
    meta: {requiresAuth: true},
    component: ()=> import(/* webpackChunkName: "User"*/ '../views/User.vue'),
  },
  {
    path: "/login",
    name:"login",
    meta: {requiresAuth: false},
    component: ()=> import(/* webpackChunkName: "Login"*/ '../views/Login.vue'),
    
  },
  {
    path: "/invoices",
    name:"invoices",
    meta: {requiresAuth: true},
    component: ()=> import(/* webpackChunkName: "Invoices"*/ '../views/Invoices.vue'),
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    meta: {requiresAuth: false},
    component: ()=> import(/* webpackChunkName: "NotFound"*/ '../views/NotFound.vue')
  }
]

// linkExactActiveClass: classe utilizzata per evidenziare il link della pagina attiva
const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "vue-school-active-class",
  scrollBehavior(to , from, savedPosition){
    if(savedPosition){
      savedPosition
    }
    else{
      if (to.hash) {
        if(document.querySelector(to.hash)){
          var offset = 0
          if(to.hash === '#experience'){
             offset = 140
          }
          return {
            selector: to.hash,
            behavior: 'smooth',
            offset: { x: 0, y: offset }
          }
        }
      }

    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  console.log(store.user, to.meta)
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !store.user) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log("OK login")
    var x = {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    }
    next(x)
  }
  else{
    next()
  }
})

// router.beforeEach((from, to, next) => {
//   // console.log("to", to)
//   if(to.matched.some(record => record.meta.requiresAuth)){
//     // need to login
//     if(!store.user){
//       next({name:"login"});
//     }
//     else{
//       next()
//     }
//   }
//   else{
//     next()
//   }
// })

export default router
